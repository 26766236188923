<template>
  <TsModal @hide="emit('hide')">
    <template #modal-header="{ modal }">
      <ClientOnly>
        <div class="flex justify-between items-center p-5 border-b">
          <div class="flex items-center gap-x-4">
            <span class="text-idle-black font-semibold">
              <Icon name="i-ci-shopping-cart-02" size="24" class="mb-0.5 mr-1" />
              {{useTranslation("itemAddedToTrolley" , "Item added to trolley")}}
            </span>
            <span class="size-1 bg-[#e5e7eb] rounded-full"></span>
            <TsAlert
              v-if="props.method === 'Collection'"
              append-class="bg-info/10 !text-info px-3 py-1 font-medium"
              >{{useTranslation("collection","Collection")}}: &nbsp;<strong> {{props.branch}}</strong></TsAlert
            >
            <TsAlert
              v-if="props.method === 'Delivery'"
              append-class="bg-success/10 !text-success px-3 py-1 font-medium"
              >{{useTranslation("delivery","Delivery")}}</TsAlert
            >
            <TsAlert
              v-if="props.method === 'Directship'"
              append-class="bg-success/10 !text-success px-3 py-1 font-medium"
              >Directship</TsAlert
            >
            <TsAlert
              v-if="props.method === 'NextDayCollection'"
              append-class="bg-success/10 !text-success px-3 py-1 font-medium"
              >Next day collection</TsAlert
            >
          </div>
          <Icon
            name="mdi:close"
            size="26"
            @click="modal?.hide()"
            class="cursor-pointer"
          />
        </div>
      </ClientOnly>
    </template>
    <template #modal-body>
      <TsMedia append-class="items-start">
        <TsMediaStart append-class="p-3 border bg-white rounded-md relative">
          <Icon
            name="material-symbols:check-circle-rounded"
            class="text-success absolute top-1.5 left-1"
            size="24"
          />
          <NuxtImg
            v-if="props.product && props.product.image"
            :src="props.product.image"
            class="h-16"
          />
        </TsMediaStart>
        <TsMediaContent>
          <span class="text-idle-black">
            <strong> {{ props.product?.brand }} </strong>
            {{ props.product?.name }}
            <span class="font-medium">{{ props.product?.pack_size }}</span>
            ({{ props.product?.code }})
          </span>
          <TsRow append-class="m-0 gap-x-8 mt-2">
            <TsTypography> {{useTranslation("quantity" , "Quantity")}} {{ quantity }}</TsTypography>
            <TsTypography>  {{rootStore.isIncVat ?  props.product?.prices?.formatted.gross: props.product?.prices?.formatted.net  }}</TsTypography>
          </TsRow>
        </TsMediaContent>
      </TsMedia>

      <div>
        <TsTypography as="span" append-class="text-idle-black mt-1">
          {{useTranslation("suggestion" , "You may also like")}}
        </TsTypography>
        <TsRow
          append-class="m-0 px-3 pb-1 flex overflow-x-auto whitespace-nowrap no-scrollbar"
          monetate-id="collection-delivery-you-may-also-like"
        >
          <TsCard
            v-for="n in 6"
            :key="n"
            append-class="p-1 m-1 w-48 bg-idle-white flex-none"
            no-border
          >
            <TsRow append-class="justify-center pt-4">
              <NuxtImg
                width="105"
                height="105"
                class="rounded-xl bg-white"
                src="/images/dewaltProductListing.png"
              />
            </TsRow>
            <TsTypography as="p" size="xs" append-class="text-right mr-2"
              >Id: 57726</TsTypography
            >

            <TsColumn>
              <TsTypography append-class="mb-0 text-idle-black"
                ><span class="font-semibold">DeWALT</span> DCH172NT-XJ XR SDS-Plus
              </TsTypography>
              <TsRow append-class="m-0 justify-between">
                <TsTypography
                  size="sm"
                  append-class="mb-0 line-through leading-tight mt-1"
                >
                  €259.00
                </TsTypography>
                <TsRatings :modelValue="1" :stars="1" label="4.5" />
              </TsRow>
              <TsRow append-class="m-0">
                <TsTypography
                  variant="h2"
                  weight="bold"
                  append-class="text-idle-black leading-normal mb-0 leading-tight"
                >
                  €199.00
                </TsTypography>
                <TsTypography append-class="mt-1 ml-2" size="xs"> $0.60/m </TsTypography>
              </TsRow>
              <TsTypography size="xs" append-class="leading-tight">
                Excl. VAT €164.46
              </TsTypography>
              <TsButton
                icon="bx:cart"
                size="sm"
                :label="addToTrolley"
                append-class="mt-3"
                outlined
                block
              />
            </TsColumn>
          </TsCard>
        </TsRow>
      </div>
    </template>

    <template #modal-footer="{ modal }">
      <div class="flex items-center gap-2 px-5">
        <TsButton :label="continueShopping" outlined block @click="modal?.hide()" />
        <TsButton :label="goToTrolley" block @click="emit('goToTrolley')" />
      </div>
    </template>
  </TsModal>
</template>

<script lang="ts" setup>
import { root } from "postcss";
import type { Product } from "~/types/ecom/product/product.type";

const rootStore = useRootStore();


type Props = {
  product: Product | null;
  quantity?: number;
  dataTestid?: string;
  method?: string;
  branch: string
};

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits<{
  goToTrolley: any;
  hide: any;
}>();

const addToTrolley = useTranslation("addToTrolley", "Add to trolley")
const continueShopping = useTranslation("continueShopping", "Continue Shopping")
const goToTrolley = useTranslation("goToTrolley", "Go to trolley")
</script>
